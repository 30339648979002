import React from "react";
import { Container, Grid } from "@mui/material";
import styled from "@emotion/styled";
import { DataImage, SectionHeading } from "../../../Styles/style";
import logo from "../../../../assets/images/logo.png";
import macbook from "../../../../assets/images/macbook.png";
import Milkswap from "../../../../assets/images/Milkswap.png";
import swap from "../../../../assets/images/swap.png";
import liquidity from "../../../../assets/images/liquidity.png";
import bridge from "../../../../assets/images/bridge.png";
import Paragraph from "../../Paragraph";
import CustomButton from "../../CustomBtn";
const ContinerWraper = styled(Container)`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 100px 0 0px !important;
  position: relative;
  @media (max-width: 899px) {
    padding: 100px 0 0px !important;
  }
  @media (max-width: 599px) {
    min-height: auto;
    padding: 100px 0 0px !important;
  }
`;

const InnerContinerWraper = styled(Container)`
  width: 100%;
  padding: 0 50px !important;
  border-radius: 20px;
  position: relative;
  z-index: 1;
  @media (max-width: 599px) {
    padding: 0 20px !important;
  }
`;

const GridWraper = styled(Grid)`
  width: 100%;
  position: relative;
  padding: 20px 0px 0;
  row-gap: 30px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  @media (max-width: 599px) {
    padding: 0;
  }
`;
const GridItem = styled(Grid)`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: 599px) {
    align-items: center;
  }
`;
const AbsoluiteImage = styled.img`
  width: 100%;
  position: absolute;
  bottom: ${(props) => (props.t ? props.t : "50%")};
  right: ${(props) => (props.l ? props.l : "5%")};
  transform: ${(props) => (props.tran ? props.tran : "translate(5%,50%)")};
  max-width: ${(props) => (props.mw ? props.mw : "300px")};
  margin: ${(props) => (props.m ? props.m : "0")};
  /* align-items: ${(props) => (props.ai ? props.ai : "center")}; */
  @media (max-width: 599px) {
    max-width: ${(props) => (props.mw599 ? props.mw599 : "250px")};
    bottom: ${(props) => (props.t599 ? props.t599 : "50%")};
    right: ${(props) => (props.l599 ? props.l599 : "50%")};
    transform: ${(props) =>
      props.tran599 ? props.tran599 : "translate(50%,50%)"};
    padding: ${(props) => (props.m599 ? props.m599 : "0 0px 0 0")};
    opacity: 0.3;
  }
  /* @media (max-width: 599px) {
    max-width: ${(props) => props.mw599};
  } */
`;
const ConnectGrid = styled(Grid)`
  width: 100%;
  background-color: ${(props) => (props.bgc ? props.bgc : "#0c0b0b")};
  display: flex;
  position: relative;
  justify-content: space-between;
  row-gap: 20px;
  border-radius: ${(props) => (props.br ? props.br : "20px")};
  margin: 0;
  padding: ${(props) => (props.p ? props.p : "40px")};
`;
const EcosystemSection = () => {
  return (
    <>
      <ContinerWraper maxWidth="xxl" id="ecosystem">
        <InnerContinerWraper>
          <GridWraper container sx={{ marginBottom: "20px" }}>
            <GridItem container item xs={12}>
              <SectionHeading
                data-aos="zoom-in"
                ta="center"
                ta599="center"
                fs="60px"
              >
                ECOSYSTEM
              </SectionHeading>
            </GridItem>
            <ConnectGrid container item xs={12}>
              <AbsoluiteImage mw="150px" mw599="150px" src={logo} />
              <GridItem
                item
                xs={12}
                sm={4}
                sx={{ alignItems: "start !important", rowGap: "20px" }}
              >
                <SectionHeading
                  data-aos="zoom-in"
                  ta="left"
                  ta599="left"
                  fs="22px"
                  fs599="22px"
                >
                  Cal Chain
                </SectionHeading>
                <Paragraph data-aos="fade-up" ta="left" ta599="left" p="10px 0">
                  Cal Chain, is a dedicated blockchain ecosystem for the Calcium
                  Token, providing innovation, security, and community
                  empowerment.
                </Paragraph>
              </GridItem>
            </ConnectGrid>
          </GridWraper>
          <ConnectGrid container p="20px">
            <ConnectGrid container br="28px" bgc="#141414" item xs={12}>
              <AbsoluiteImage mw="350px" mw599="350px" src={macbook} />
              <GridItem
                item
                xs={12}
                sm={7}
                sx={{ alignItems: "start !important", rowGap: "10px" }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    columnGap: "10px",
                  }}
                >
                  <DataImage mw="50px" src={Milkswap} />
                  <SectionHeading
                    data-aos="zoom-in"
                    ta="left"
                    ta599="left"
                    fs="22px"
                    fs599="22px"
                  >
                    Milkswap
                  </SectionHeading>
                </div>
                <Paragraph data-aos="fade-up" ta="left" ta599="left" p="10px 0">
                  Introducing MilkSwap, Cal Chain's decentralized exchange
                  (DEX), where you'll milk the most from your crypto assets!
                </Paragraph>
                <CustomButton
                  bgc="#373737"
                  c="#848484 !important"
                  b="none"
                  variant="disabled"
                >
                  Coming Soon
                </CustomButton>
              </GridItem>
            </ConnectGrid>
            <ConnectGrid
              container
              br="28px"
              bgc="#212121"
              item
              xs={12}
              md={3.9}
            >
              <GridItem item xs={12} sx={{ rowGap: "5px" }}>
                <DataImage mw="35px" data-aos="zoom-in" src={swap} />
                <Paragraph
                  data-aos="fade-up"
                  ta="center"
                  ta599="center"
                  p="10px 0"
                >
                  Token Swap
                </Paragraph>
                <Paragraph
                  data-aos="fade-up"
                  ta="center"
                  ta599="center"
                  p="10px 0"
                >
                  Seamlessly enhance your portfolio with Cal Chain's Token Swap,
                  simplifying asset management.
                </Paragraph>
              </GridItem>
            </ConnectGrid>
            <ConnectGrid
              br="28px"
              container
              bgc="#303030"
              item
              xs={12}
              md={3.9}
            >
              <GridItem item xs={12} sx={{ rowGap: "5px" }}>
                <DataImage mw="30px" data-aos="zoom-in" src={liquidity} />
                <Paragraph
                  data-aos="fade-up"
                  ta="center"
                  ta599="center"
                  p="10px 0"
                >
                  Liquidity Pool Creation
                </Paragraph>
                <Paragraph
                  data-aos="fade-up"
                  ta="center"
                  ta599="center"
                  p="10px 0"
                >
                  Boost your earnings with ease on Cal Chain through Liquidity
                  Pool Creation, where your assets work harder for you.
                </Paragraph>
              </GridItem>
            </ConnectGrid>
            <ConnectGrid
              br="28px"
              container
              bgc="#505050"
              item
              xs={12}
              md={3.9}
            >
              <GridItem item xs={12} sx={{ rowGap: "5px" }}>
                <DataImage mw="30px" data-aos="zoom-in" src={bridge} />
                <Paragraph
                  data-aos="fade-up"
                  ta="center"
                  ta599="center"
                  p="10px 0"
                >
                  Bridge
                </Paragraph>
                <Paragraph
                  data-aos="fade-up"
                  ta="center"
                  ta599="center"
                  p="10px 0"
                >
                  Connect and transact effortlessly across networks using Cal
                  Chain's Bridge, making cross-chain interactions a breeze.
                </Paragraph>
              </GridItem>
            </ConnectGrid>
          </ConnectGrid>
        </InnerContinerWraper>
      </ContinerWraper>
    </>
  );
};

export default EcosystemSection;
