import styled from "@emotion/styled";
import { Container, Grid, Link } from "@mui/material";
import React from "react";
import image1 from "../../../../assets/images/logo.png";
import medium from "../../../../assets/images/medium.png";
import twitter from "../../../../assets/images/twitter.png";
import telegram from "../../../../assets/images/telegram.png";
import Paragraph from "../../Paragraph";
import { ColumnGrid, SectionHeading, DataImage } from "../../../Styles/style";
import TelegramIcon from "@mui/icons-material/Telegram";
import CustomButton from "../../CustomBtn";
import TwitterIcon from "@mui/icons-material/Twitter";

const ContinerWraper = styled(Container)`
  background: #212121;
  position: relative;
  height: auto;
  width: 100%;
  padding: 40px 0 0 !important;
`;
const ContinerWrap = styled(Container)`
  background: #0c0b0b;
  position: relative;
  border-top-right-radius: 30px;
  border-top-left-radius: 30px;
  height: auto;
  width: 100%;
  padding: 50px 0 !important;
`;

const Text = styled.input`
  background: #2b2b2b !important;
  border: none;
  padding: 10px;
  margin: 10px 0;
  border-radius: 13px;
  color: #fff;
  width: 30%;
 @media (max-width: 899px) {
  width: 100%;
  }
  font-family: segoepr;
  :focus {
    outline: none;
  }
`;

const Div = styled(Grid)`
  display: flex;
  align-items: center;
  flex-direction: column;
  row-gap: 10px !important;
  @media (max-width: 899px) {
    align-items: start;
  }
`;

const Footer = () => {
  return (
    <>
      <ContinerWrap maxWidth="xxl" id="footer">
        <Container>
          <Grid
            container
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              rowGap: "30px",
            }}
          >
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                rowGap: "20px",
              }}
            >
              <Paragraph  data-aos="zoom-in">Cal Chain Newsletter</Paragraph>
            </Grid>
            <Grid
              item
              xs={12}
              sm={5}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                rowGap: "20px",
              }}
              data-aos="zoom-in"
            >
              <SectionHeading ta="center" fs="30px" fs599="22px">
                Stay Updated On The Latest Cal Chain News
              </SectionHeading>
            </Grid>
            
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                columnGap: "20px",
              }}
              data-aos="fade-up"
            >
              <Text placeholder="Enter Your Email Address" />
              <CustomButton fs599="16px" hc="#fff">
                Signup
              </CustomButton>
            </Grid>
          </Grid>
        </Container>
      </ContinerWrap>
      <ContinerWraper maxWidth="xxl">
        <Container>
          <Grid
            container
            sx={{
              display: "flex",
              justifyContent: "space-between",
              rowGap: "20px",
              zIndex: "1",
              columnGap: "30px",
              padding: "0 0 70px",
            }}
          >
            <Grid item xs={12} md={3}  data-aos="fade-up">
              <ColumnGrid ai="start">
                <DataImage mw="50px" src={image1} />
              </ColumnGrid>

              <Paragraph p="20px 0 30px" ta="left" ta599="left" fs="14px">
                Cal Chain, is a dedicated blockchain ecosystem for the Calcium
                Token, providing innovation, security, and community
                empowerment.
              </Paragraph>
              <Paragraph color="#ffffff85" p="10px 0 0" ta="left" ta599="left" >
                © 2023 Cal Chain
              </Paragraph>
            </Grid>
            <Grid
              container
              item
              xs={12}
              md={7}
              style={{ display: "flex", justifyContent: "space-around" }}
            >
              <Div item xs={12} sm={4} md={3} sx={{ paddingTop: "30px" }} data-aos="fade-up">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    rowGap: "20px !important",
                  }}
                >
                  <SectionHeading
                    ta="left"
                    fs="16px"
                    fs599="16px"
                    p="0px 0 10px"
                  >
                    Technology
                  </SectionHeading>
                  <Link sx={{ textDecoration: "none" }} href="">
                    <SectionHeading
                      ta="left"
                      fs="14px"
                      fs599="14px"
                      p="5px 0"
                      p599="5px 0"
                      p899="5px 0"
                    >
                      Calc Token
                    </SectionHeading>
                  </Link>
                  <Link sx={{ textDecoration: "none" }} href="">
                    <SectionHeading
                      ta="left"
                      fs="14px"
                      fs599="14px"
                      p="5px 0"
                      p599="5px 0"
                      p899="5px 0"
                    >
                      Cal Chain Blockchain
                    </SectionHeading>
                  </Link>
                  <Link sx={{ textDecoration: "none" }} href="">
                    <SectionHeading
                      ta="left"
                      fs="14px"
                      fs599="14px"
                      p="5px 0"
                      p599="5px 0"
                      p899="5px 0"
                    >
                      MilkSwap
                    </SectionHeading>
                  </Link>
                  <Link sx={{ textDecoration: "none" }} href="">
                    <SectionHeading
                      ta="left"
                      fs="14px"
                      fs599="14px"
                      p="5px 0"
                      p599="5px 0"
                      p899="5px 0"
                    >
                      Bridge
                    </SectionHeading>
                  </Link>
                </div>
              </Div>
              <Div item xs={12} sm={4} md={2} sx={{ paddingTop: "30px" }}  data-aos="fade-up">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    rowGap: "20px !important",
                  }}
                >
                  <SectionHeading
                    ta="left"
                    fs="16px"
                    fs599="16px"
                    p="0px 0 10px"
                  >
                    Tools
                  </SectionHeading>
                  <Link sx={{ textDecoration: "none" }} href="">
                    <SectionHeading
                      ta="left"
                      fs="14px"
                      fs599="14px"
                      p="5px 0"
                      p599="5px 0"
                      p899="5px 0"
                    >
                      Faucet
                    </SectionHeading>
                  </Link>
                  <Link sx={{ textDecoration: "none" }} href="">
                    <SectionHeading
                      ta="left"
                      fs="14px"
                      fs599="14px"
                      p="5px 0"
                      p599="5px 0"
                      p899="5px 0"
                    >
                      CalScan
                    </SectionHeading>
                  </Link>
                </div>
              </Div>
              <Div item xs={12} sm={4} md={2} sx={{ paddingTop: "30px" }}  data-aos="fade-up">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    rowGap: "20px !important",
                  }}
                >
                  <SectionHeading
                    ta="left"
                    fs="16px"
                    fs599="16px"
                    p="0px 0 10px"
                  >
                    Socials
                  </SectionHeading>

                  <Link
                    sx={{ textDecoration: "none" }}
                    href="https://t.me/calceth"
                    target="blank"
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "start",
                        columnGap: "5px",
                      }}
                    >
                      <DataImage mw="35px" src={telegram} />
                      <SectionHeading
                        ta="left"
                        fs="14px"
                        fs599="14px"
                        p="5px 0"
                        p599="5px 0"
                        p899="5px 0"
                      >
                        Telegram
                      </SectionHeading>
                    </div>
                  </Link>
                  <Link
                    sx={{ textDecoration: "none" }}
                    href="https://twitter.com/calchaineth"
                    target="blank"
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "start",
                        columnGap: "5px",
                      }}
                    >
                      <DataImage mw="22px" src={twitter} />
                      <SectionHeading
                        ta="left"
                        fs="14px"
                        fs599="14px"
                        p="5px 0"
                        p599="5px 0"
                        p899="5px 0"
                      >
                        Twitter
                      </SectionHeading>
                    </div>
                  </Link>
                  {/* <Link sx={{ textDecoration: "none" }} href="" target="blank">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "start",
                        columnGap: "5px",
                      }}
                    >
                      <DataImage mw="20px" src={medium} />
                      <SectionHeading
                        ta="left"
                        fs="14px"
                        fs599="14px"
                        p="5px 0"
                        p599="5px 0"
                        p899="5px 0"
                      >
                        Medium
                      </SectionHeading>
                    </div>
                  </Link> */}
                </div>
              </Div>
            </Grid>
          </Grid>
        </Container>
      </ContinerWraper>
    </>
  );
};

export default Footer;
