import React from "react";
import { Container, Grid, Typography } from "@mui/material";
import styled from "@emotion/styled";
import { AbsoluiteImage, SectionHeading } from "../../../Styles/style";
import heroImage from "../../../../assets/images/heroImage.png";
import Paragraph from "../../Paragraph";
import LockerMenu from "../../LockerMenu";
import CustomButton from "../../CustomBtn";
const ContinerWraper = styled(Container)`
  overflow: hidden;
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 !important;
  position: relative;
  @media (max-width: 599px) {
    /* min-height: calc(100vh - 200px); */
  }
`;

const InnerContinerWraper = styled(Container)`
  /* min-height: calc(100vh - 130px); */
  width: 100%;
  padding: 0 50px !important;
  border-radius: 20px;
  position: relative;
  z-index: 1;
  @media (max-width: 599px) {
    background-position: left;
    padding: 0 20px !important;
    /* min-height: auto; */
  }
`;

const GridWraper = styled(Grid)`
  width: 100%;
  min-height: calc(100vh - 110px);
  position: relative;
  padding: 50px 0px 0;
  display: flex;
  align-items: start;
  justify-content: center;
  @media (max-width: 599px) {
    padding: 30px 0px 70px;
    min-height: calc(100vh - 110px);
    align-items: center;
  }
`;
const GridItem = styled(Grid)`
  width: 100%;
  position: relative;
  display: flex;
  justify-content: space-around;
  align-items: center;
  @media (max-width: 599px) {
    align-items: start;
  }
`;
const Heading = styled(Typography)`
  padding: ${(props) => (props.p ? props.p : "0px")};
  margin: ${(props) => (props.m ? props.m : "0")};
  text-align: ${(props) => (props.ta ? props.ta : "center")};
  font-size: ${(props) => (props.fs ? props.fs : "150px")};
  font-family: ${(props) => (props.fm ? props.fm : "segoepr")};
  line-height: 1;
  color: #fff;
  -webkit-text-stroke: 1px #000;
  text-transform: uppercase;
  @media (max-width: 1099px) {
    padding: ${(props) => props.p599};
    font-size: ${(props) => (props.fs599 ? props.fs599 : "120px")};
  }
  @media (max-width: 899px) {
    padding: ${(props) => props.p599};
    font-size: ${(props) => (props.fs599 ? props.fs599 : "90px")};
  }
  
  @media (max-width: 599px) {
    padding: ${(props) => props.p599};
    font-size: ${(props) => (props.fs599 ? props.fs599 : "50px")};
  }
`;
const ContractDiv = styled.div`
  width: 100%;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 0px;
`;
const HeroSection = () => {
  return (
    <ContinerWraper maxWidth="xxl" id="home">
      <ContractDiv>
        <Paragraph color="#000">
          Contract Address: 0xBC639079Ec68c583294cecD52a9dCD1561B70340
        </Paragraph>
      </ContractDiv>
      <LockerMenu />
      <InnerContinerWraper>
        <GridWraper container>
          <AbsoluiteImage mw="40%" mw999="50%" src={heroImage} />
          <Grid container item xs={12}>
            <GridItem item xs={12}>
              <SectionHeading
                ta="center"
                fs="50px"
                fs599="22px"
                ta599="left"
                p="0 50px 100px 0"
                p599="0 100px 50px 0"
                // p599=""
                data-aos="fade-right"
                sx={{width:"60%"}}
              >
                Powerful For <br /> Developers
              </SectionHeading>
              <SectionHeading
                ta="left"
                // ta599="center"
                fs="50px"
                fs599="22px"
                p="50px 0 0 0px"
                p599="50px 0 0 30px"
                sx={{width:"40%"}}
                data-aos="fade-left"
              >
                Fast for <br /> everyone
              </SectionHeading>
            </GridItem>
            <GridItem item xs={12}>
              <Heading data-aos="zoom-in">Cal Chain</Heading>
            </GridItem>
          </Grid>
          <Grid cntainer item xs={12} sx={{
                display: "flex",
                justifyContent: "center",
              }}>
            <Grid
              item
              xs={12}
              md={5}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                rowGap: "20px",
                justifyContent: "center",
              }}
            >
              <Paragraph ta="center" sx={{ position: "relative", zIndex: "9" }}>
                Create – trade – earn on complete ecosystem. <br /> The future
                of meme coin sharing on cal chain.
              </Paragraph>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  columnGap: "10px",
                }}
              >
                <CustomButton href='https://app.uniswap.org/swap?outputCurrency=0xBC639079Ec68c583294cecD52a9dCD1561B70340&chain=ethereum' target='blank' hc="#fff">Buy Now</CustomButton>
                <CustomButton href='https://www.dextools.io/app/en/ether/pair-explorer/0xcea2924b787ec1200dba80d1dcb890b8124bffd4' target='blank' bgc="transparent" c="#fff" hc="#000" hbgc="#fff">
                  Live Chart
                </CustomButton>
              </div>
            </Grid>
          </Grid>
          {/* <Grid
            cntainer
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "center",
              columnGap: "10px",
            }}
          >
          </Grid> */}
        </GridWraper>
      </InnerContinerWraper>
    </ContinerWraper>
  );
};

export default HeroSection;
