import React from "react";
import { Container, Grid } from "@mui/material";
import styled from "@emotion/styled";
import { DataImage, SectionHeading } from "../../../Styles/style";
import aboutImage from "../../../../assets/images/aboutImage.png";
import aboutBg from "../../../../assets/images/aboutBg.png";
import Paragraph from "../../Paragraph";
import CustomButton from "../../CustomBtn";
const ContinerWraper = styled(Container)`
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 100px 0 300px !important;
  position: relative;
  @media (min-width: 1439px) {
    padding: 0px 0 500px !important;
  }
  @media (max-width: 899px) {
    padding: 0px 0 200px !important;
  }
  @media (max-width: 599px) {
    min-height: auto;
    padding: 00px 0 150px !important;
  }
`;

const InnerContinerWraper = styled(Container)`
  width: 100%;
  padding: 0 50px !important;
  border-radius: 20px;
  position: relative;
  z-index: 1;
  @media (max-width: 599px) {
    padding: 0 20px !important;
  }
`;

const GridWraper = styled(Grid)`
  width: 100%;
  position: relative;
  padding: 20px 0px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 599px) {
    padding: 0;
  }
`;
const GridItem = styled(Grid)`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  @media (max-width: 599px) {
    align-items: center;
  }
`;
const AbsoluiteImage = styled.img`
  width: 100%;
  position: absolute;
  bottom: ${(props) => (props.t ? props.t : "0%")};
  left: ${(props) => (props.l ? props.l : "50%")};
  transform: ${(props) => (props.tran ? props.tran : "translate(-50%,0%)")};
  max-width: ${(props) => (props.mw ? props.mw : "100%")};
  margin: ${(props) => (props.m ? props.m : "0")};
  /* align-items: ${(props) => (props.ai ? props.ai : "center")}; */
  @media (max-width: 599px) {
    max-width: ${(props) => (props.mw599 ? props.mw599 : "100%")};
    top: ${(props) => (props.t599 ? props.t599 : props.t599)};
    left: ${(props) => (props.l599 ? props.l599 : props.l599)};
    padding: ${(props) => (props.m599 ? props.m599 : "0 0px 0 0")};
  }
  /* @media (max-width: 599px) {
    max-width: ${(props) => props.mw599};
  } */
`;
const AboutSection = () => {
  return (
    <>
      <ContinerWraper maxWidth="xxl" id="about">
        <AbsoluiteImage mw="100%" src={aboutBg} />
        <InnerContinerWraper>
          <GridWraper container>
            <GridItem container item xs={12} sm={6}>
              <SectionHeading
                data-aos="zoom-in"
                ta="left"
                ta599="left"
                fs="60px"
              >
                What is cal chain?
              </SectionHeading>
              <Grid item xs={12} sx={10} md={9}>
                <Paragraph data-aos="fade-up" ta="left" ta599="left" p="10px 0">
                  Cal Chain is a dedicated blockchain ecosystem meticulously
                  crafted to empower and support the Calcium Token (CAL). It
                  stands as a robust and innovative platform designed to enhance
                  the Calcium community's crypto journey. Cal Chain, hosted at
                  calchain.io, offers a comprehensive suite of features,
                  including token swaps, liquidity pool creation, and seamless
                  cross-chain interactions through its Bridge. At its core, Cal
                  Chain's Layer 2 blockchain fuels efficiency, enabling
                  lightning-fast transactions while keeping costs remarkably low
                  with "CAL Gas." This ecosystem sets a new standard for
                  blockchain, marrying speed and affordability to create a
                  thriving and accessible crypto space for all.
                </Paragraph>
                <CustomButton href='https://cal-chain.gitbook.io' target='blank' hc="#fff">Read More</CustomButton>
              </Grid>
            </GridItem>
            <GridItem container item xs={12} sm={5}>
              <DataImage data-aos="zoom-in" mw="300px" src={aboutImage} />
            </GridItem>
          </GridWraper>
        </InnerContinerWraper>
      </ContinerWraper>
    </>
  );
};

export default AboutSection;
