import React, { useEffect } from "react";
import HeroSection from "../../Components/Sections/S1-Hero";
import { PageWrapper } from "../../Styles/style";
import AboutSection from "../../Components/Sections/S2-About";
import TokenSection from "../../Components/Sections/S6-Tokenomics";
import AOS from "aos";
import "aos/dist/aos.css";
import FeaturesSection from "../../Components/Sections/S3-Features";
import EcosystemSection from "../../Components/Sections/S4-Ecosystem";
import Partner from "../../Components/Sections/S5-Partners/partner";
import FAQSection from "../../Components/Sections/S9-FAQ";
import Footer from "../../Components/Sections/S10-Footer";
import RoadmapSection from "../../Components/Sections/S8-Roadmap";
const MainPage = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);
  return (
    <PageWrapper>
      <HeroSection />
      <AboutSection />
      <FeaturesSection />
      <EcosystemSection />
      <Partner />
      <TokenSection />
      <RoadmapSection />
      <FAQSection />
      <Footer />
    </PageWrapper>
  );
};

export default MainPage;
