import React from "react";
import { Container, Grid } from "@mui/material";
import metamask from "../../../../assets/images/metamask.png";
import trustwallet from "../../../../assets/images/trustwallet.png";
import dextools from "../../../../assets/images/dextools.png";
import dexscrener from "../../../../assets/images/dexscrener.png";
import uniswap from "../../../../assets/images/uniswap.png";
import etherscan from "../../../../assets/images/etherscan.png";
import calcoin from "../../../../assets/images/calcoin.png";
import styled from "@emotion/styled";
import { DataImage } from "../../../Styles/style";

const ImageGrid = styled(Grid)`
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 899px) {
    flex-direction: column;
  }
`;

const Div = styled.div`
  display: inline-flex;
    column-gap: 110px;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    row-gap: 20px;
    @media(max-width: 599px){
      /* justify-content: space-between; */
      column-gap: 90px;
      padding: 0 20px;
    }
`;

const ContinerWraper1 = styled(Container)`
  /* background: radial-gradient(circle at 50% 60%, #1177f37a, transparent 50%); */
  width: 100%;
  padding: 100px 0;
  @media (max-width: 599px) {
    
  }
`;

const Partner = () => {
  return (
    <>
      <ContinerWraper1>
        <Grid container>
          {/* <Grid item xs={12}>
            <SectionHeading ta="center" p="30px 0" fs="60px" data-aos="zoom-in">
              PARTNERS
            </SectionHeading>
          </Grid> */}
          <ImageGrid item xs={12} md={12}>
            <Div>
              <DataImage
                mw="120px"
                src={metamask}
                data-aos="fade-up"
                data-aos-duration="1000"
              />
              <DataImage
                mw="120px"
                src={trustwallet}
                data-aos="fade-up"
                data-aos-duration="1300"
              />
              <DataImage
                mw="90px"
                src={dextools}
                data-aos="fade-up"
                data-aos-duration="1600"
              />
              <DataImage
                mw="120px"
                m599="0 0 0 30px"
                src={dexscrener}
                data-aos="fade-up"
                data-aos-duration="1900"
              />
              <DataImage
                mw="120px"
                src={uniswap}
                data-aos="fade-up"
                data-aos-duration="2200"
              />
              <DataImage
                mw="120px"
                src={etherscan}
                data-aos="fade-up"
                data-aos-duration="2500"
              />
              <DataImage
                mw="90px"
                src={calcoin}
                data-aos="fade-up"
                data-aos-duration="2800"
              />
              {/* <DataImage
                mw="120px"
                src={solidity}
                data-aos="fade-up"
                data-aos-duration="3200"
              /> */}
            </Div>
          </ImageGrid>
        </Grid>
      </ContinerWraper1>
    </>
  );
};

export default Partner;
