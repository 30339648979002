import React from "react";
import { Container, Grid } from "@mui/material";
import styled from "@emotion/styled";
import { DataImage, SectionHeading } from "../../../Styles/style";
import open from "../../../../assets/images/open.png";
import Paragraph from "../../Paragraph";
const ContinerWraper = styled(Container)`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px 0 0px !important;
  position: relative;
  @media (max-width: 599px) {
    min-height: auto;
  }
`;

const InnerContinerWraper = styled(Container)`
  width: 100%;
  padding: 0 50px !important;
  border-radius: 20px;
  position: relative;
  z-index: 1;
  @media (max-width: 599px) {
    padding: 0 20px !important;
  }
`;

const GridItem = styled(Grid)`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: 599px) {
    align-items: center;
  }
`;

const ConnectGrid = styled(Grid)`
  width: 100%;
  background-color: ${(props) => (props.bgc ? props.bgc : "#0c0b0b")};
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: start;
  row-gap: 10px;
  border-radius: ${(props) => (props.br ? props.br : "20px")};
  margin: 0;
  padding: ${(props) => (props.p ? props.p : "40px")};
`;
const IFrame = styled.iframe`
  border: 0;
  margin: 0 auto;
  display: block;
  border-radius: 10px;
  max-width: 100%;
  min-height: calc(100vh - 50px);
`;
const TokenSection = () => {
  return (
    <>
      <ContinerWraper maxWidth="xxl" id="tokenomics">
        <InnerContinerWraper>
          <ConnectGrid container p="40px 20px">
            <GridItem container item xs={12}>
              <SectionHeading
                data-aos="zoom-in"
                ta="center"
                p="10px 0 40px"
                ta599="center"
                fs="60px"
              >
                TOKENOMICS
              </SectionHeading>
            </GridItem>

            <ConnectGrid
              container
              br="28px"
              p="15px 20px"
              bgc="#212121"
              item
              xs={12}
              md={3.9}
            >
              <GridItem item xs={12} sx={{ rowGap: "5px" }}>
                <Paragraph
                  data-aos="fade-up"
                  ta="center"
                  ta599="center"
                  p="10px 0"
                >
                  Symbol
                </Paragraph>
                <SectionHeading
                  data-aos="zoom-in"
                  ta="center"
                  ta599="center"
                  fs="37px"
                >
                  CalC
                </SectionHeading>
              </GridItem>
            </ConnectGrid>
            <ConnectGrid
              br="28px"
              container
              p="15px 20px"
              bgc="#303030"
              item
              xs={12}
              md={3.9}
            >
              <GridItem item xs={12} sx={{ rowGap: "5px" }}>
                <Paragraph
                  data-aos="fade-up"
                  ta="center"
                  ta599="center"
                  p="10px 0"
                >
                  Supply
                </Paragraph>
                <SectionHeading
                  data-aos="zoom-in"
                  ta="center"
                  ta599="center"
                  fs="37px"
                >
                  420,690,000
                </SectionHeading>
              </GridItem>
            </ConnectGrid>
            <ConnectGrid
              br="28px"
              container
              p="15px 20px"
              bgc="#505050"
              item
              xs={12}
              md={3.9}
            >
              <GridItem item xs={12} sx={{ rowGap: "5px" }}>
                <Paragraph
                  data-aos="fade-up"
                  ta="center"
                  ta599="center"
                  p="10px 0"
                >
                  Taxes
                </Paragraph>
                <SectionHeading
                  data-aos="zoom-in"
                  ta="center"
                  ta599="center"
                  fs="37px"
                >
                  3/4
                </SectionHeading>
              </GridItem>
            </ConnectGrid>
            <ConnectGrid container br="28px" bgc="#141414" item xs={12}>
              <GridItem
                item
                xs={12}
                sx={{
                  flexDirection: "row !important",
                  justifyContent: "space-between !important",
                  alignItems: "start !important",
                  rowGap: "10px",
                }}
              >
                <Paragraph data-aos="fade-up" ta="left" ta599="left" p="10px 0">
                  Token Explorer
                </Paragraph>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "center",
                    columnGap: "5px",
                  }}
                  data-aos="fade-up"
                >
                  <Paragraph
                    ta="right"
                    ta599="right"
                    p="10px 0"
                  >
                    Etherscan
                  </Paragraph>
                  <DataImage mw="30px" src={open} />
                </div>
              </GridItem>
              <GridItem
                item
                xs={12}
                sx={{
                  flexDirection: "row !important",
                  justifyContent: "space-between !important",
                  alignItems: "start !important",
                  rowGap: "10px",
                }}
              >
                <Paragraph data-aos="fade-up" ta="left" ta599="left" p="10px 0">
                  Liquidity Locked
                </Paragraph>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "center",
                    columnGap: "5px",
                  }}
                  
                  data-aos="fade-up"
                >
                  <Paragraph
                    ta="right"
                    ta599="right"
                    p="10px 0"
                  >
                    TBA
                  </Paragraph>
                  <DataImage mw="30px" src={open} />
                </div>
              </GridItem>
              <GridItem
                item
                xs={12}
                sx={{
                  flexDirection: "row !important",
                  justifyContent: "space-between !important",
                  alignItems: "start !important",
                  rowGap: "10px",
                }}
              >
                <Paragraph data-aos="fade-up" ta="left" ta599="left" p="10px 0">
                  Contract Renounced
                </Paragraph>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "center",
                    columnGap: "5px",
                  }}
                  data-aos="fade-up"
                >
                  <Paragraph
                   
                    ta="right"
                    ta599="right"
                    p="10px 0"
                  >
                    Etherscan
                  </Paragraph>
                  <DataImage mw="30px" src={open} />
                </div>
              </GridItem>
              <GridItem
                item
                xs={12}
                sx={{
                  flexDirection: "row !important",
                  justifyContent: "space-between !important",
                  alignItems: "start !important",
                  rowGap: "10px",
                }}
              >
                <Paragraph data-aos="fade-up" ta="left" ta599="left" p="10px 0">
                  Max TX/Wallet will be temporary at launch to prevent dumping.
                </Paragraph>
              </GridItem>
            </ConnectGrid>
            <IFrame
              title="uniswap"
              src="https://app.uniswap.org/#/swap?theme=dark"
              height="100%"
              width="100%"
            />
          </ConnectGrid>
        </InnerContinerWraper>
      </ContinerWraper>
    </>
  );
};

export default TokenSection;
