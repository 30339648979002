import styled from "@emotion/styled";
import { Grid, Typography } from "@mui/material";

const PageWrapper = styled.div`
  background-color: #000;
  position: relative;
  overflow: hidden !important;
`;
const DataImage = styled.img`
  width: 100%;
  max-width: ${(props) => (props.mw ? props.mw : "90%")};
  margin: ${(props) => (props.m ? props.m : "0px")};
  /* align-items: ${(props) => (props.ai ? props.ai : "center")}; */
   @media (max-width: 899px) {
    max-width: ${(props) => (props.mw899 ? props.mw899 : "")};
  }
  @media (max-width: 599px) {
    max-width: ${(props) => (props.mw599 ? props.mw599 : "")};
    margin: ${(props) => (props.m599 ? props.m599 : "0")};
    display: ${(props) => (props.disp ? props.disp : "")};
    visibility: ${(props) => (props.vis ? props.vis : "")};
  }
`;
const AbsoluiteImage = styled.img`
  width: 100%;
  position: absolute;
  top: ${(props) => (props.t ? props.t : "0%")};
  left: ${(props) => (props.l ? props.l : "50%")};
  transform: ${(props) => (props.tran ? props.tran : "translate(-50%,0%)")};
  max-width: ${(props) => (props.mw ? props.mw : "100%")};
  margin: ${(props) => (props.m ? props.m : "0")};
  /* align-items: ${(props) => (props.ai ? props.ai : "center")}; */
  @media (max-width: 999px) {
    max-width: ${(props) => props.mw999};
  }
  @media (max-width: 599px) {
    max-width: ${(props) => (props.mw599 ? props.mw599 : "100%")};
    top: ${(props) => (props.t599 ? props.t599 : props.t599)};
    left: ${(props) => (props.l599 ? props.l599 : props.l599)};
    padding: ${(props) => (props.m599 ? props.m599 : "0 0px 0 0")};
  }
`;
const SectionHeading = styled(Typography)`
  padding: ${(props) => (props.p ? props.p : "0px")};
  margin: ${(props) => (props.m ? props.m : "0")};
  text-align: ${(props) => (props.ta ? props.ta : "left")};
  font-size: ${(props) => (props.fs ? props.fs : "36px")};
  font-family: ${(props) => (props.fm ? props.fm : "segoepr")};
  line-height: 1;
  color: #fff;
  @media (max-width: 599px) {
  text-align: ${(props) => props.ta599};
    padding: ${(props) => props.p599};
    font-size: ${(props) => (props.fs599 ? props.fs599 : "34px")};
  }
`;
const ColumnGrid = styled(Grid)`
  display: flex;
  flex-direction: column;
  align-items: ${(props) => (props.ai ? props.ai : "center")};
  justify-content: ${(props) => (props.jc ? props.jc : "center")};
  padding: ${(props) => (props.p ? props.p : "0px")};
  margin: ${(props) => (props.m ? props.m : "0")};
`;
export { PageWrapper, DataImage, AbsoluiteImage,ColumnGrid, SectionHeading };
