import styled from "@emotion/styled";
import { Container, Grid } from "@mui/material";
import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { SectionHeading } from "../../../Styles/style";
import Paragraph from "../../Paragraph";

const ContinerWraper = styled(Container)`
  position: relative;
  height: auto;
  width: 100%;
  padding: 100px 0 !important;
`;

const StyledAccordion = styled(Accordion)`
  background: #0c0b0b !important;
`;
const Summary = styled(AccordionSummary)`
  background: #0c0b0b !important;
`;
const Details = styled(AccordionDetails)`
  background: #0c0b0b !important;
`;
const FAQSection = () => {
  return (
    <ContinerWraper maxWidth="xxl" id="faq">
      <Container>
        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: "center",
            rowGap: "20px",
            zIndex: "1",
            columnGap: "30px",
          }}
        >
          <Grid item xs={12}>
            <SectionHeading
              data-aos="zoom-in"
              ta="center"
              p="10px 0 40px"
              ta599="center"
              fs="60px"
            >
              FAQ
            </SectionHeading>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{ display: "flex", flexDirection: "column", rowGap: "20px" }}
          >
            <StyledAccordion>
              <Summary
                expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Paragraph fw="bold" fs="16px" fs599="14px" ta="left" ta599="left">
                  What is cal chain?
                </Paragraph>
              </Summary>
              <Details>
                <Paragraph fs="14px" ta="left" ta599="left">
                  Cal Chain is a dedicated blockchain ecosystem designed to
                  empower and support the Calcium Token (CAL). It offers a range
                  of features, including token swaps, liquidity pool creation,
                  and seamless cross-chain interactions.
                </Paragraph>
              </Details>
            </StyledAccordion>
            <StyledAccordion>
              <Summary
                expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Paragraph fw="bold" fs="16px" fs599="14px" ta="left" ta599="left">
                  How can I get involved with Cal Chain?
                </Paragraph>
              </Summary>
              <Details>
                <Paragraph fs="14px" ta="left" ta599="left">
                  You can get involved with Cal Chain by joining our community
                  and utilizing the platform's features. Start by linking your
                  wallet and exploring the opportunities for token swaps and
                  liquidity pool creation.
                </Paragraph>
              </Details>
            </StyledAccordion>
            <StyledAccordion>
              <Summary
                expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
                aria-controls="panel3a-content"
                id="panel3a-header"
              >
                <Paragraph ta="left" ta599="left" fw="bold" fs="16px" fs599="14px">
                  What makes Cal Chain unique in the crypto space?
                </Paragraph>
              </Summary>
              <Details>
                <Paragraph fs="14px" ta="left" ta599="left">
                  Cal Chain stands out for its commitment to speed and
                  affordability. Our Layer 2 blockchain ensures rapid
                  transactions. Additionally, Cal Chain is built to serve the
                  Calcium Token community, providing tailored features and
                  support.
                </Paragraph>
              </Details>
            </StyledAccordion>
            <StyledAccordion>
              <Summary
                expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
                aria-controls="panel3a-content"
                id="panel3a-header"
              >
                <Paragraph ta="left" ta599="left" fw="bold" fs="16px" fs599="14px">
                  What's the roadmap for Cal Chain's development?
                </Paragraph>
              </Summary>
              <Details>
                <Paragraph fs="14px" ta="left" ta599="left">
                  Cal Chain follows a three-stage roadmap, including the Launch
                  Phase, Calchain Phase, and Moon Phase. The Launch Phase
                  focuses on foundational development, the Calchain Phase
                  emphasizes growth and community involvement, and the Moon
                  Phase represents widespread adoption and innovation. Check our
                  roadmap for detailed milestones.
                </Paragraph>
              </Details>
            </StyledAccordion>
          </Grid>
        </Grid>
      </Container>
    </ContinerWraper>
  );
};

export default FAQSection;
