import React from "react";
import DesktopMenu from "./component/DesktopMenu";
import MobileMenu from "./component/MobileMenu";
import { Container } from "@mui/material";

const LockerMenu = (props) => {
  const menuList = [
    // You can pass 4 perameters for menu title, link, target and customClass
    {
      title: "Home",
      link: "#home",
    },
    {
      title: "About",
      link: "#about",
    },
    {
      title: "Features",
      link: "#fetures",
    },
    {
      title: "Ecosystem",
      link: "#ecosystem",
    },
    {
      title: "Tokenomics",
      link: "#tokenomics",
    },
    {
      title: "Roadmap",
      link: "#roadmap",
    },
    {
      title: "Whitepaper",
      link: "https://cal-chain.gitbook.io",
      target:'blank'
    },

  ];
  return (
    <Container sx={{padding:"0  !important"}}>
      <DesktopMenu menuList={menuList} />
      <MobileMenu menuList={menuList} />
    </Container>
  );
};

export default LockerMenu;
