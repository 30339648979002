import React from "react";
import { Container, Grid } from "@mui/material";
import styled from "@emotion/styled";
import { DataImage, SectionHeading } from "../../../Styles/style";
import blockchain from "../../../../assets/images/blockchain.png";
import calgas from "../../../../assets/images/calchain.png";
import fast from "../../../../assets/images/fast.png";
import connetImage from "../../../../assets/images/connetImage.png";
import Paragraph from "../../Paragraph";
const ContinerWraper = styled(Container)`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 100px 0 0px !important;
  position: relative;
  @media (max-width: 899px) {
    padding: 100px 0 0px !important;
  }
  @media (max-width: 599px) {
    min-height: auto;
    padding: 100px 0 0px !important;
  }
`;

const InnerContinerWraper = styled(Container)`
  width: 100%;
  padding: 0 50px !important;
  border-radius: 20px;
  position: relative;
  z-index: 1;
  @media (max-width: 599px) {
    padding: 0 20px !important;
  }
`;

const GridWraper = styled(Grid)`
  width: 100%;
  position: relative;
  padding: 20px 0px 0;
  row-gap: 30px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  @media (max-width: 599px) {
    padding: 0;
  }
`;
const GridItem = styled(Grid)`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: 599px) {
    align-items: center;
  }
`;
const AbsoluiteImage = styled.img`
  width: 100%;
  position: absolute;
  bottom: ${(props) => (props.t ? props.t : "50%")};
  right: ${(props) => (props.l ? props.l : "5%")};
  transform: ${(props) => (props.tran ? props.tran : "translate(5%,50%)")};
  max-width: ${(props) => (props.mw ? props.mw : "300px")};
  margin: ${(props) => (props.m ? props.m : "0")};
  /* align-items: ${(props) => (props.ai ? props.ai : "center")}; */
  @media (max-width: 599px) {
    max-width: ${(props) => (props.mw599 ? props.mw599 : "250px")};
    bottom: ${(props) => (props.t599 ? props.t599 : "50%")};
    right: ${(props) => (props.l599 ? props.l599 : "50%")};
    transform: ${(props) =>
      props.tran599 ? props.tran599 : "translate(50%,50%)"};
    padding: ${(props) => (props.m599 ? props.m599 : "0 0px 0 0")};
    opacity: 0.3;
  }
  /* @media (max-width: 599px) {
    max-width: ${(props) => props.mw599};
  } */
`;
const ConnectGrid = styled(Grid)`
  width: 100%;
  background-color: #0c0b0b;
  display: flex;
  position: relative;
  justify-content: start;
  align-items: start;
  row-gap: 20px;
  border-radius: 13px;
  margin: 0;
  padding: 40px;
`;
const FeaturesSection = () => {
  return (
    <>
      <ContinerWraper maxWidth="xxl" id="fetures">
        <InnerContinerWraper>
          <GridWraper container sx={{ marginBottom: "100px" }}>
            <GridItem container item xs={12}>
              <SectionHeading
                data-aos="zoom-in"
                ta="center"
                ta599="center"
                fs="60px"
                p="0 0 50px"
              >
                FEATURES
              </SectionHeading>
            </GridItem>
            <GridItem item xs={12} sm={3}>
              <DataImage mw="40px" data-aos="zoom-in" src={blockchain} />
              <Paragraph
                data-aos="fade-up"
                ta="center"
                ta599="center"
                p="10px 0"
              >
                Layer 2 Blockchain
              </Paragraph>
              <Paragraph
                data-aos="fade-up"
                ta="center"
                ta599="center"
                p="10px 0"
              >
                Cal Chain's Layer 2 blockchain powers efficiency, ensuring swift
                transactions and lower fees.
              </Paragraph>
            </GridItem>
            <GridItem item xs={12} sm={3}>
              <DataImage mw="45px" data-aos="zoom-in" src={calgas} />
              <Paragraph
                data-aos="fade-up"
                ta="center"
                ta599="center"
                p="10px 0"
              >
                CAL Gas
              </Paragraph>
              <Paragraph
                data-aos="fade-up"
                ta="center"
                ta599="center"
                p="10px 0"
              >
                Experience the lightning efficiency of CAL Gas, fueling your
                transactions in mere moments.
              </Paragraph>
            </GridItem>
            <GridItem item xs={12} sm={3}>
              <DataImage mw="45px" data-aos="zoom-in" src={fast} />
              <Paragraph
                data-aos="fade-up"
                ta="center"
                ta599="center"
                p="10px 0"
              >
                Cheap And Fast
              </Paragraph>
              <Paragraph
                data-aos="fade-up"
                ta="center"
                ta599="center"
                p="10px 0"
              >
                Cal Chain redefines blockchain with its exceptional speed and
                cost-effectiveness.
              </Paragraph>
            </GridItem>
          </GridWraper>
          <ConnectGrid container>
            <AbsoluiteImage src={connetImage} />
            <GridItem
              item
              xs={12}
              md={5}
              sx={{ alignItems: "start !important", rowGap: "20px" }}
            >
              <SectionHeading
                data-aos="zoom-in"
                ta="left"
                ta599="left"
                fs="60px"
              >
                CONNECT CAL CHAIN
              </SectionHeading>
              <Paragraph data-aos="fade-up" ta="left" ta599="left" p="10px 0">
                Chain ID: TBA <br />
                RPC: TBA <br />
                Explorer: TBA <br />
                Symbol: CAL
              </Paragraph>
            </GridItem>
          </ConnectGrid>
        </InnerContinerWraper>
      </ContinerWraper>
    </>
  );
};

export default FeaturesSection;
