import React from "react";
import { Container, Grid } from "@mui/material";
import styled from "@emotion/styled";
import { SectionHeading } from "../../../Styles/style";
import roadmap from "../../../../assets/images/roadmap.png";
import Paragraph from "../../Paragraph";
const ContinerWraper = styled(Container)`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 100px 0 0px !important;
  position: relative;
  @media (max-width: 899px) {
    padding: 100px 0 0px !important;
  }
  @media (max-width: 599px) {
    min-height: auto;
    padding: 100px 0 0px !important;
  }
`;

const InnerContinerWraper = styled(Container)`
  width: 100%;
  padding: 0 50px !important;
  border-radius: 20px;
  position: relative;
  z-index: 1;
  @media (max-width: 599px) {
    padding: 0 20px !important;
  }
`;

const GridWraper = styled(Grid)`
  width: 100%;
  position: relative;
  padding: 20px 0px 0;
  row-gap: 30px;
  display: flex;
  align-items: start;
  justify-content: space-between;
  @media (max-width: 599px) {
    padding: 0;
  }
`;
const GridItem = styled(Grid)`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  @media (max-width: 599px) {
    align-items: start;
  }
`;
const AbsoluiteImage = styled.img`
  width: 100%;
  position: absolute;
  bottom: ${(props) => (props.t ? props.t : "24%")};
  right: ${(props) => (props.l ? props.l : "55%")};
  transform: ${(props) => (props.tran ? props.tran : "translate(55%,24%)")};
  max-width: ${(props) => (props.mw ? props.mw : "50%")};
  margin: ${(props) => (props.m ? props.m : "0")};
  /* align-items: ${(props) => (props.ai ? props.ai : "center")}; */
  @media (max-width: 999px) {
    display: none;
  }
`;
const ConnectGrid = styled(Grid)`
  width: 100%;
  min-height: 300px;
  background-color: ${(props) => (props.bgc ? props.bgc : "#212121")};
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: start;
  row-gap: 10px;
  border-radius: ${(props) => (props.br ? props.br : "20px")};
  margin: ${(props) => (props.m ? props.m : "0px")};
  padding: ${(props) => (props.p ? props.p : "40px")};
  @media (max-width: 999px) {
    margin: 0;
  }
`;
const RoadmapSection = () => {
  return (
    <>
      <ContinerWraper maxWidth="xxl" id="roadmap">
        <InnerContinerWraper>
          <GridWraper container>
            <AbsoluiteImage src={roadmap} />
            <GridItem item xs={12} sx={{ alignItems: "center !important" }}>
              <SectionHeading
                data-aos="zoom-in"
                ta="center"
                p="10px 0 40px"
                ta599="center"
                fs="60px"
              >
                ROADMAP
              </SectionHeading>
            </GridItem>

            <ConnectGrid
              container
              m="300px 0 0"
              br="28px"
              p="15px 20px"
              item
              xs={12}
              md={3.9}
            >
              <GridItem item xs={12} sx={{ rowGap: "5px" }}>
                <Paragraph
                  data-aos="fade-up"
                  ta="left"
                  ta599="left"
                  fs="18px"
                  fs599="16px"
                  p="10px 0"
                >
                  Launch Phase
                </Paragraph>
                <Paragraph data-aos="fade-up" ta="left" ta599="left">
                  - Design & creation <br /> <br />
                  - Website launch <br /> <br />
                  - Faucet <br /> <br />
                  - Testnet <br /> <br />
                  - Whitepaper <br /> <br />
                  - Cal Chain Mainnet <br /> <br />
                </Paragraph>
              </GridItem>
            </ConnectGrid>
            <ConnectGrid
              br="28px"
              m="150px 0 0"
              container
              p="15px 20px"
              item
              xs={12}
              md={3.9}
            >
              <GridItem item xs={12} sx={{ rowGap: "5px" }}>
                <Paragraph
                  data-aos="fade-up"
                  ta="left"
                  ta599="left"
                  fs="18px"
                  fs599="16px"
                  p="10px 0"
                >
                  Cal Chain Phase
                </Paragraph>
                <Paragraph data-aos="fade-up" ta="left" ta599="left">
                  - Launch of Milkswap <br /> <br />
                  - Bridge release <br /> <br />
                  - Blockscout explorer <br /> <br />
                  - Collaboration with the community for feedback and
                  refinement <br /> <br />
                  - Strengthening the bond between CAL token holders and Cal
                  Chain. <br /> <br />
                  - Community engagement and support <br /> <br />
                </Paragraph>
              </GridItem>
            </ConnectGrid>
            <ConnectGrid
              br="28px"
              container
              p="15px 20px"
              item
              xs={12}
              md={3.9}
            >
              <GridItem item xs={12} sx={{ rowGap: "5px" }}>
                <Paragraph
                  data-aos="fade-up"
                  ta="left"
                  ta599="left"
                  fs="18px"
                  fs599="16px"
                  p="10px 0"
                >
                  Moon Phase
                </Paragraph>
                <Paragraph data-aos="fade-up" ta="left" ta599="left">
                  - Widespread adoption of Cal Chain
                  <br /> <br />
                  - Facilitation of innovative use cases <br /> <br />
                  - Continuous platform improvement <br /> <br />
                </Paragraph>
              </GridItem>
            </ConnectGrid>
          </GridWraper>
        </InnerContinerWraper>
      </ContinerWraper>
    </>
  );
};

export default RoadmapSection;
